import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import { dates } from '../constants'

class Vysledky extends React.Component {
	render() {
		return (
			<Layout>
			<SEO
			title="Výsledky"
			description={`Výsledky šifrovačky Navíc, která se konala v Praze ${dates.game}.`}
			/>
				<div id="main">
					<section id="one">
						<div className="inner">
							<header className="major">
								<h2>Výsledky</h2>
							</header>
							<p>Do oficiální výsledkové listiny jsou zařazeny týmy, které odevzdaly cílové heslo. Na neoficiální výsledky a další zajímavá data se můžete podívat <a href="https://statek.seslost.cz/navic-2021/statistiky-sifer">na Statku</a> (děkujeme, Tome).</p>

							<h3>Pořadí v kategorii <em>Na čas</em></h3>
							<p>(Pořadí pro kategorii <em>Víc bodů</em> najdete níž na stránce)</p>

<table className="nobreak">
	<thead>
		<tr>
			<th>#</th>
			<th>Název týmu</th>
			<th>Cíl</th>
		</tr>
	</thead>
<tbody>
	<tr><td>1</td><td>Spící Volové</td><td>15:13:46</td></tr>
	<tr><td>2</td><td>Divize Nulou</td><td>16:27:07</td></tr>
	<tr><td>3</td><td>Pralinky zvlášť</td><td>17:04:23</td></tr>
	<tr><td>4</td><td>poTrati</td><td>17:23:42</td></tr>
	<tr><td>5</td><td>Borci v uvozovkách</td><td>17:51:44</td></tr>
	<tr><td>6</td><td>Tlupa</td><td>18:11:22</td></tr>
	<tr><td>7</td><td>Bifurkační tým</td><td>18:41:03</td></tr>
	<tr><td>8</td><td>Trubky</td><td>18:47:14</td></tr>
	<tr><td>9</td><td>Na výletě</td><td>18:52:47</td></tr>
	<tr><td>10</td><td>Trosečníci</td><td>19:02:18</td></tr>
	<tr><td>11</td><td>Psychodegen</td><td>19:07:37</td></tr>
	<tr><td>12</td><td>Nevyskúšaš, nevieš</td><td>19:26:57</td></tr>
	<tr><td>13</td><td>Hledáme metro</td><td>19:34:56</td></tr>
	<tr><td>14</td><td>Set sail for fail</td><td>19:58:16</td></tr>
	<tr><td>15</td><td>Sakrblé</td><td>19:58:53</td></tr>
	<tr><td>16</td><td>Happy Matfyz Friends</td><td>20:13:14</td></tr>
	<tr><td>17</td><td>¯\_(ツ)_/¯</td><td>20:14:09</td></tr>
	<tr><td>18</td><td>Šmeydíci</td><td>20:27:09</td></tr>
	<tr><td>19</td><td>Absinthenti</td><td>20:33:09</td></tr>
	<tr><td>20</td><td>Pomocná škola</td><td>20:36:25</td></tr>
	<tr><td>21</td><td>Mločí oči</td><td>20:41:07</td></tr>
	<tr><td>22</td><td>Kukytým</td><td>20:44:00</td></tr>
	<tr><td>23</td><td>ČSFR</td><td>20:45:23</td></tr>
	<tr><td>24</td><td>Tranquilo, señor!</td><td>20:48:56</td></tr>
	<tr><td>25</td><td>Hlavně se nezranit</td><td>20:49:35</td></tr>
	<tr><td>26</td><td>Popocatepetl</td><td>20:50:27</td></tr>
	<tr><td>27</td><td>Večernice</td><td>20:50:44</td></tr>
	<tr><td>28</td><td>Radkův tým</td><td>20:52:26</td></tr>
	<tr><td>29</td><td>Povyrostlo nám seno</td><td>20:52:26</td></tr>
	<tr><td>30</td><td>Freezy Bee</td><td>20:53:19</td></tr>
	<tr><td>31</td><td>KVÍK!</td><td>20:54:07</td></tr>
	<tr><td>32</td><td>Brouk v hlavě</td><td>20:54:37</td></tr>
	<tr><td>33</td><td>Svinský Bob</td><td>20:55:18</td></tr>
	<tr><td>34</td><td>Program na sobotu</td><td>20:55:31</td></tr>
	<tr><td>35</td><td>Proudoví Krtci</td><td>20:55:48</td></tr>
	<tr><td>36</td><td>JTHTN</td><td>20:56:01</td></tr>
	<tr><td>37</td><td>Fčeličky</td><td>20:56:10</td></tr>
	<tr><td>38</td><td>Nadbytečný pleonasmus</td><td>20:56:21</td></tr>
	<tr><td>39</td><td>Želvonohý</td><td>20:56:22</td></tr>
	<tr><td>40</td><td>O stupeň navíc</td><td>20:56:31</td></tr>
	<tr><td>41</td><td>Out of memory</td><td>20:56:58</td></tr>
	<tr><td>42</td><td>O.H.L.A.</td><td>20:57:30</td></tr>
	<tr><td>43</td><td>ˇ&lt;</td><td>20:57:58</td></tr>
	<tr><td>44</td><td>Zvířátka</td><td>20:58:13</td></tr>
	<tr><td>45</td><td>Tykadla</td><td>20:58:43</td></tr>
	<tr><td>46</td><td>Kanonický divisor</td><td>20:59:01</td></tr>
</tbody>
</table>

<br />

							<h3>Pořadí v kategorii <em>Víc bodů</em></h3>

							<table className="nobreak"><thead><tr><th>#</th>
<th>Název týmu</th>
<th>Body</th></tr></thead><tbody>
<tr><td>1</td><td>¯\_(ツ)_/¯</td><td>100</td></tr>
<tr><td>2</td><td>Nadbytečný pleonasmus</td><td>91</td></tr>
<tr><td>3</td><td>Radkův tým</td><td>90</td></tr>
<tr><td>4</td><td>ˇ&lt;</td><td>85</td></tr>
<tr><td>5</td><td>Tranquilo, señor!</td><td>79</td></tr>
<tr><td>6</td><td>Program na sobotu</td><td>77</td></tr>
<tr><td>7</td><td>O stupeň navíc</td><td>72</td></tr>
<tr><td>8</td><td>Povyrostlo nám seno</td><td>69</td></tr>
<tr><td>9</td><td>Zvířátka</td><td>69</td></tr>
<tr><td>10</td><td>Hlavně se nezranit</td><td>58</td></tr>
<tr><td>11</td><td>Set sail for fail</td><td>48</td></tr>
<tr><td>12</td><td>Out of memory</td><td>38</td></tr>
<tr><td>13</td><td>O.H.L.A.</td><td>38</td></tr>
<tr><td>14</td><td>Proudoví Krtci</td><td>37</td></tr>
<tr><td>15</td><td>Tykadla</td><td>37</td></tr>
<tr><td>16</td><td>Brouk v hlavě</td><td>33</td></tr>
<tr><td>17</td><td>Freezy Bee</td><td>32</td></tr>
<tr><td>18</td><td>Kanonický divisor</td><td>31</td></tr>
<tr><td>19</td><td>Želvonohý</td><td>27</td></tr>
<tr><td>20</td><td>Svinský Bob</td><td>26</td></tr>
<tr><td>21</td><td>KVÍK!</td><td>24</td></tr>
<tr><td>22</td><td>Na výletě</td><td>23</td></tr>
<tr><td>23</td><td>JTHTN</td><td>22</td></tr>
<tr><td>24</td><td>Fčeličky</td><td>21</td></tr>
<tr><td>25</td><td>Kukytým</td><td>17</td></tr>
<tr><td>26</td><td>Hledáme metro</td><td>15</td></tr>
<tr><td>27</td><td>Trosečníci</td><td>13</td></tr>
<tr><td>28</td><td>Spící Volové</td><td>11</td></tr>
<tr><td>29</td><td>Absinthenti</td><td>11</td></tr>
<tr><td>30</td><td>Sakrblé</td><td>10</td></tr>
<tr><td>31</td><td>Divize Nulou</td><td>8</td></tr>
<tr><td>32</td><td>Bifurkační tým</td><td>8</td></tr>
<tr><td>33</td><td>poTrati</td><td>6</td></tr>
<tr><td>34</td><td>ČSFR</td><td>6</td></tr>
<tr><td>35</td><td>Tlupa</td><td>5</td></tr>
<tr><td>36</td><td>Trubky</td><td>3</td></tr>
<tr><td>37</td><td>Šmeydíci</td><td>3</td></tr>
<tr><td>38</td><td>Pomocná škola</td><td>3</td></tr>
<tr><td>39</td><td>Mločí oči</td><td>2</td></tr>
<tr><td>40</td><td>Nevyskúšaš, nevieš</td><td>1</td></tr>
<tr><td>41</td><td>Happy Matfyz Friends</td><td>1</td></tr>
<tr><td>42</td><td>Popocatepetl</td><td>1</td></tr>
<tr><td>43</td><td>Večernice</td><td>1</td></tr>
<tr><td>44</td><td>Pralinky zvlášť</td><td>0</td></tr>
<tr><td>45</td><td>Borci v uvozovkách</td><td>0</td></tr>
<tr><td>46</td><td>Psychodegen</td><td>0</td></tr>
</tbody></table>


						</div>
					</section>
				</div>
			</Layout>
		)
	}
}
export default Vysledky
